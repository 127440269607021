import React from 'react';
import 'react-vertical-timeline-component/style.min.css';
import { Container } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faGraduationCap } from '@fortawesome/free-solid-svg-icons';

import Title from '../Title/Title';

const Projects = () => {
  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Work Experience & Education" />
          <VerticalTimeline className="vertical-timeline-custom-line">
            <VerticalTimelineElement
              className="vertical-timeline-custom-line vertical-timeline-element--work"
              contentStyle={{ color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #5B84B1FF' }}
              date="2021 - Present"
              iconStyle={{ background: '#5B84B1FF', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faBriefcase} size="2x" pull="center" />}
            >
              <h3 className="vertical-timeline-element-title">Software Engineer @Checkout51</h3>
              <p className="vertical-timeline-element-description">
                PHP/MySQL/Javascript <br />
                Symfony|VueJS
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-custom-line vertical-timeline-element--work"
              contentStyle={{ color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #5B84B1FF' }}
              date="2016 - 2021"
              iconStyle={{ background: '#5B84B1FF', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faBriefcase} size="2x" pull="center" />}
            >
              <h3 className="vertical-timeline-element-title">Software Developer @Link2Feed</h3>
              <p className="vertical-timeline-element-description">
                PHP/Ruby/MySQL/PostgreSQL/Javascript/Node.JS <br />
                Symfony| Rails | ReactJS
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-custom-line vertical-timeline-element--work"
              contentStyle={{ color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #FC766AFF' }}
              date="2016 - 2016"
              iconStyle={{ background: '#5B84B1FF', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faBriefcase} size="2x" />}
            >
              <h3 className="vertical-timeline-element-title">
                Software Developer @Lambton College
              </h3>
              <p className="vertical-timeline-element-description">
                PHP/Ruby/MySQL/PostgreSQL/Javascript/Node.JS <br />
                Symfony| Rails
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #5B84B1FF' }}
              date="2015 - 2015"
              iconStyle={{ background: '#5B84B1FF', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faBriefcase} size="2x" />}
            >
              <h3 className="vertical-timeline-element-title">
                Student Web Developer @Lambton College
              </h3>
              <p className="vertical-timeline-element-description">
                PHP/Ruby/MySQL/PostgreSQL/Javascript/Node.JS <br />
                Symfony| Rails | ReactJS
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-custom-line vertical-timeline-element--work"
              contentStyle={{ color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #FC766AFF' }}
              date="2014 - 2015"
              iconStyle={{ background: '#5B84B1FF', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faGraduationCap} size="2x" />}
            >
              <h3 className="vertical-timeline-element-title">
                Internet Programming and Database Management
              </h3>
              <p className="vertical-timeline-element-description">Lambton College, Sarnia, ON</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-custom-line vertical-timeline-element--work"
              contentStyle={{ color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  #5B84B1FF' }}
              date="2011 - 2014"
              iconStyle={{ background: '#5B84B1FF', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faGraduationCap} size="2x" />}
            >
              <h3 className="vertical-timeline-element-title">
                Bachelors in Computer Applications
              </h3>
              <p className="vertical-timeline-element-description">Gujarat University, India</p>
            </VerticalTimelineElement>

            {/* <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#000' }}
          // icon={<StarIcon />}
      /> */}
          </VerticalTimeline>
        </div>
      </Container>
    </section>
  );
};

export default Projects;
